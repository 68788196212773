@use 'sass:color';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

@mixin parentBackgroundLink() {
  position: relative;
  font-family: var.$archivo-narrow;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1.15px;
}

@mixin parentBackground($opacity: 0.12) {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--header-text-color, #fff);
    opacity: $opacity;
    border-radius: var.$radius-xs;
  }
}

@mixin parentStyles() {
  padding: var.$spacing-xs 0.5rem;
  font-size: 0.875rem;
  line-height: 1.2;
  letter-spacing: 0.15px;

  &:hover {
    background-color: inherit;
  }
}

.block {
  @include mix.flex;

  .menu {
    @include mix.flex;
  }

  .menuItem {
    position: relative;
    white-space: nowrap;

    a {
      @include mix.flex($gap: var.$spacing-xs);

      @include parentStyles;
    }

    .dropdown {
      display: none;
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      height: auto;
      overflow: hidden;
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 20%);
      min-width: max-content;
      padding: 0.25rem 0;
    }

    &:hover,
    &:focus-within {
      .dropdown {
        display: block;
      }
    }

    .dropdownItem a {
      display: block;
      padding: 0.625rem 1rem;
      letter-spacing: 0.15px;

      &:hover {
        color: inherit;
        background-color: rgb(0 0 0 / 5%);
      }
    }
  }

  .arrow {
    height: 0.375rem;
    fill: currentcolor;
  }
}

// Expanded menu styles
// This is currently only attributed if there's a secondary menu (secondary, site)
.block.expanded {
  .menu {
    gap: var.$spacing-sm;

    &:is([data-header-theme='secondary'] .menu) {
      gap: 0;
    }
  }

  // All links in the site trending menu should have a background color
  .menuItem a:is([data-header-theme='site'] a) {
    @include parentBackgroundLink;

    font-size: 0.625rem;

    @include parentBackground($opacity: 0.1);
  }

  @media screen and (max-width: var.$desktop-width - 1px) {
    .menuItem,
    .dropdown {
      @include mix.flex;
    }

    .menuItem:has(> .dropdown) {
      > a {
        margin-right: var.$spacing-xs;

        @include parentBackground;
      }

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &:not(:first-child) {
        margin-left: 1rem;
      }
    }

    .menuItem {
      &:hover,
      &:focus-within {
        .dropdown {
          display: flex;
        }
      }
    }

    .dropdown {
      z-index: 0;
      position: relative;
      box-shadow: none;
    }

    .dropdownItem a {
      @include parentStyles;
    }

    .arrow {
      display: none;
    }
  }
}
