@use '@on3/styles/variables' as var;

.base {
  height: 0.75rem;
  width: auto;
  fill: var.$gray;

  &.on3 {
    height: 1.25rem;
  }
}
