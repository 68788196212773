@use '@on3/styles/on3/theme';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.form {
  display: grid;
  gap: var.$spacing-regular;
  background-color: #fff;
  padding: var.$spacing-lg;
  margin: var.$spacing-sm;
  border-radius: var.$radius-md;
  width: 100%;
  max-width: var.$tablet-width;

  [data-ui='input'] input {
    @extend %input;
  }

  [data-ui='textarea'] textarea {
    @extend %textarea;
  }

  [data-ui='input-label'],
  [data-ui='textarea-label'] {
    font-family: var.$archivo-narrow;
  }

  .header {
    @include mix.flex($justify: space-between);

    h2 {
      @extend %h2;
    }

    .btnClose {
      position: relative;
      border-radius: var.$radius-full;
      height: 2rem;
      width: 2rem;

      @extend %btn-mui;

      @include mix.flex($justify: center);

      svg {
        font-size: 1rem;
      }
    }
  }

  .message {
    letter-spacing: normal;
  }

  .fields {
    display: grid;
    gap: var.$spacing-sm;
  }

  .error,
  .success {
    font-size: 0.875rem;
    letter-spacing: normal;
    text-align: center;
  }

  .error {
    color: var.$negativeDay;
  }

  .success {
    color: var.$positiveDayDark;
  }

  .submitContainer {
    @include mix.flex($justify: flex-end);

    button[type='submit'] {
      @extend %btn-primary;
    }
  }
}
