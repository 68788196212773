@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  @include mix.flex($gap: 0.5rem);

  color: var.$white;
  border: 2px solid var.$newGreen;
  border-radius: 9999px;
  padding: var.$spacing-xs;
  padding-left: var.$spacing-sm;

  span {
    font-size: 0.75rem;
    letter-spacing: 0.65px;
  }

  .toggle {
    @include mix.flex;

    position: relative;
    width: calc(2 * 0.875rem + 6px);
    background-color: var.$neutral;
    border-radius: 9999px;
    padding: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    .knob {
      display: block;
      width: 0.875rem;
      aspect-ratio: 1;
      background-color: var.$white;
      border-radius: 50%;
      transition: 0.3s ease-in-out;
      box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    }
  }

  &[data-status='active'] {
    .toggle {
      background-color: var.$newGreen;
    }

    .knob {
      transform: translateX(100%);
    }
  }
}
