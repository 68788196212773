@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

$mobile-header-height: 50px;

.block {
  @include mix.flex($gap: var.$spacing-xs);

  @media screen and (min-width: var.$desktop-width) {
    display: none;
  }

  .on3 {
    @include mix.flex($inline: true);

    @media screen and (min-width: var.$small-width) {
      display: none;
    }
  }
}

.button {
  display: flex;
  color: inherit;
  padding: var.$spacing-sm;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: currentcolor;
  }
}

.overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 50%) !important;
}

.menu {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  animation: slide-in 0.3s ease forwards;

  @media screen and (min-width: var.$mobile-width) {
    max-width: var.$mobile-width;
    width: 100%;
  }

  .header {
    @include mix.flex($gap: var.$spacing-xs);

    position: relative;
    z-index: 1;
    height: $mobile-header-height;
    padding: var.$spacing-sm 1rem;
    padding-left: calc(1rem - var.$spacing-sm);
    border-bottom: 1px solid rgba(var.$gray, 0.1);
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);

    a {
      display: inline-flex;
      align-items: center;
      padding: var.$spacing-xs;
    }

    .logo {
      height: 1.5rem;
      fill: currentcolor;
      width: auto;
    }
  }

  .inner {
    flex: 1;
    overflow: auto;
    padding-bottom: 7rem;
  }

  .top {
    @include mix.flex($justify: flex-end);

    padding: var.$spacing-regular var.$spacing-regular var.$spacing-xs;

    .on3 {
      @include mix.flex($inline: true);

      margin-right: auto;

      &:not([data-theme='channel'], [data-theme='highSchool']) svg {
        fill: currentcolor;
      }
    }

    &:empty {
      display: none;
    }
  }

  .menuItem,
  .dropdown {
    @include mix.spaceY(var.$spacing-xs);

    a {
      display: block;
      padding: var.$spacing-compact;
    }
  }

  .menuItem {
    padding: var.$spacing-sm calc(var.$spacing-regular - var.$spacing-compact);
    border-bottom: 1px solid rgba(var.$gray, 0.1);

    & > a {
      line-height: calc(1rem * 1.2);
      font-size: 0.675rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .dropdown {
    a {
      color: inherit;
      opacity: 0.9;
    }
  }
}
