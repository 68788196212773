@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.block {
  position: relative;

  @include mix.flex;

  * {
    color: currentcolor;
  }

  .button {
    position: relative;
    font-size: 1rem;
    height: 2rem;
    width: 2rem;
    border-radius: var.$radius-full;
    background-color: rgb(0 0 0 / 10%);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      height: 0.85em;
      width: 0.85em;
      fill: currentcolor;
    }
  }

  [data-menu-ui='user-menu'] {
    position: absolute;
    top: calc(100% + 2px);
    right: 0;
    z-index: 100;
    white-space: nowrap;
    width: auto;
    min-width: 200px;
    padding: 0.25rem 0;
    list-style: none;
    border-radius: var.$radius-sm;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    animation: slide-up 0.2s ease-in-out;

    a,
    button {
      @include mix.flex($gap: var.$spacing-sm);

      width: 100%;
      font-size: 0.875rem;
      line-height: 1.2;
      padding: 0.625rem var.$spacing-regular;
      letter-spacing: 0.15px;

      &:hover {
        color: inherit;
        background-color: rgb(0 0 0 / 5%);
      }

      svg {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }
}
