@use 'sass:string';

@use '@on3/styles/variables' as var;

@mixin on3MQ() {
  @media only screen and (min-width: var.$mid-width) and (max-width: var.$sidebar-break),
    only screen and (min-width: var.$desktop-width) {
    @content;
  }
}

@mixin on3FiveStarMQ() {
  @media only screen and (min-width: var.$fivestar-mid-width) and (max-width: var.$sidebar-break),
    only screen and (min-width: var.$desktop-width) {
    @content;
  }
}

@mixin homelayout {
  .feed {
    grid-area: middle;
  }

  .sidebarright {
    grid-area: right;

    @media screen and (min-width: 961px) {
      position: sticky;
      display: block;
    }

    @media screen and (min-width: 961px) and (min-height: 800px) {
      top: 1.25rem;
    }
  }

  .sidebarleft {
    grid-area: left;

    @media screen and (min-width: 1140px) {
      position: sticky;
      top: 1.25rem;
      display: block;
    }
  }

  .inner {
    height: 100%;

    @include flex($direction: column, $align: stretch, $gap: var.$spacing-lg);
  }
}

@mixin smallGrayFont() {
  font-size: 0.75rem;
  color: var.$gray;
  letter-spacing: 0.15px;
}

@mixin xsmallGrayFont() {
  font-size: 0.625rem;
  color: var.$gray;
  letter-spacing: 0.15px;
}

@mixin pageTitle() {
  text-transform: capitalize;

  @media (min-width: var.$mid-width) {
    font-size: 2.625rem;
  }
}

@mixin labelFont() {
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 1.15px;
  line-height: 1.5;
  text-transform: uppercase;
}

@mixin valueFont() {
  font-family: var.$archivo-narrow;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 1;
}

@mixin flex(
  $direction: row,
  $align: center,
  $justify: flex-start,
  $inline: false,
  $wrap: 'nowrap',
  $gap: 0
) {
  @if $inline {
    display: inline-flex;
  }

 @else {
    display: flex;
  }

  flex-direction: #{$direction};
  align-items: #{$align};
  justify-content: #{$justify};
  flex-wrap: #{$wrap};
  gap: #{$gap};
}

@mixin spaceY($space) {
  & > * {
    margin-top: #{$space};
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

@mixin spaceX($space) {
  & > * {
    margin-left: #{$space};
  }

  & > *:first-child {
    margin-left: 0;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

@mixin dashedBorder($color: #000, $width: 2, $dash: 8, $gap: 8) {
  $color: string.slice('#{$color}', 2);

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23#{$color}' stroke-width='#{$width}' stroke-dasharray='#{$dash}%2c #{$gap}' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

@mixin lineClamp($lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
