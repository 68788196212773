@font-face {
  font-family: 'Geist';
  src:
    url('https://on3static.com/fonts/Geist-UltraBlack.woff2') format('woff2'),
    url('https://on3static.com/fonts/Geist-UltraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist';
  src:
    url('https://on3static.com/fonts/Geist-Black.woff2') format('woff2'),
    url('https://on3static.com/fonts/Geist-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist';
  src:
    url('https://on3static.com/fonts/Geist-Bold.woff2') format('woff2'),
    url('https://on3static.com/fonts/Geist-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist';
  src:
    url('https://on3static.com/fonts/Geist-SemiBold.woff2') format('woff2'),
    url('https://on3static.com/fonts/Geist-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist';
  src:
    url('https://on3static.com/fonts/Geist-Medium.woff2') format('woff2'),
    url('https://on3static.com/fonts/Geist-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist';
  src:
    url('https://on3static.com/fonts/Geist-Regular.woff2') format('woff2'),
    url('https://on3static.com/fonts/Geist-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
