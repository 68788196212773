@use '@on3/styles/base';

@use '@on3/styles/variables' as var;

@use 'sass:color';

.button {
  @extend %btn;

  padding: var.$spacing-sm 1rem;
  background-color: var.$newGreen;
  color: var.$night;

  &:hover {
    background-color: color.adjust(var.$newGreen, $lightness: -10%);
  }
}
