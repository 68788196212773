@use '@on3/styles/variables' as var;

.btnCallToAction {
  background-color: var.$premiumGreen;
  color: var.$white;
  height: 50px;
  border: 1px solid var.$premiumGreen;

  &:hover {
    background-color: var.$container;
    color: var.$premiumGreen;
  }
}

// force deployment
.btncallout {
  background-color: var.$premiumGreen;
  color: var.$white;
  border: 1px solid var.$premiumGreen;
  border-radius: 3px;

  &:hover {
    background-color: var.$container;
    color: var.$premiumGreen;
  }
}

.btnIconText {
  color: var.$night;
  border: 1px solid var.$lightGray;
  display: flex;
  align-self: center;
  height: 50px;
  background-color: var.$lightGray;

  &:hover {
    background-color: var.$container;
    color: var.$night;
  }
}

.labelWrapper {
  display: grid;
  grid-template-columns: 20% 60% 20%;
}

.btnUpload {
  font-size: 13px;
  line-height: 32px;

  &:hover {
    background-color: var.$container;
    color: var.$orange;

    .uploadIcon {
      path {
        fill: var.$orange;
      }
    }
  }

  .uploadWrapper {
    display: flex;
    justify-content: center;
  }
}
