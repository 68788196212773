@use '@on3/styles/variables' as var;

.base {
  position: sticky;
  top: 0;

  // Connatix has a z-index of 9999. We want this header behind that.
  z-index: 9998;
  height: auto;
  transition: all 0.25s ease;

  &.scrolled {
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  }

  @media screen and (max-width: calc(var.$mid-width - 1px)) {
    &.hidden {
      transform: translateY(-100%);
      box-shadow: none;

      & > * {
        opacity: 0;
        pointer-events: none; // hides dropdowns when hidden due to lack of hover, unless focused upon
      }
    }
  }
}
