@use 'sass:color';

@use './animations';

@use './variables' as var;

@use './mixins' as mix;

$primary: var.$black !default;

%btn {
  position: relative;
  display: inline-block;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  border-color: transparent;
  padding: 0.75rem 1.25rem;
  border-radius: var.$radius-sm;
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: 0.15px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.25;
  }
}

%btn-primary {
  @extend %btn;

  background-color: $primary;
  color: var.$white;

  &:hover {
    background-color: color.adjust($primary, $lightness: -10%);
  }
}

%btn-secondary {
  @extend %btn;

  background-color: rgba(var.$white, 0.12);

  &:hover {
    background-color: color.adjust(rgba(var.$white, 0.12), $lightness: -50%);
  }
}

%btn-tertiary {
  @extend %btn;

  color: $primary;
  background-color: transparent;

  &:hover {
    color: var.$white;
    background-color: color.adjust($primary, $lightness: -10%);
  }
}

%btn-outline {
  @extend %btn;

  background-color: transparent;
  border: 1px solid $primary;
  color: $primary;
  box-shadow: none;

  &:hover {
    background-color: $primary;
    color: var.$white;
  }
}

%btn-gray {
  @extend %btn;

  background-color: var.$lightNeutralGray;

  &:hover {
    background-color: color.adjust(var.$lightNeutralGray, $lightness: -5%);
  }
}

%btn-neutral {
  @extend %btn;

  background-color: var.$lightNeutral;

  &:hover {
    background-color: color.adjust(var.$lightNeutral, $lightness: -5%);
  }
}

%btn-small {
  @extend %btn;

  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

%btn-subscribe {
  @extend %btn;

  border-radius: 0;
  background-color: var.$newGreen;
  font-weight: 600;
  padding: 3px 6px;
  text-transform: uppercase;
  color: var.$night;
}

%btn-flat {
  @extend %btn;

  background-color: transparent;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

%btn-icon {
  line-height: 1;

  @include mix.flex($gap: var.$spacing-xs);

  svg {
    font-size: 1.2em;
    fill: currentcolor;
  }
}

%btn-mui {
  &:hover {
    background-color: var.$lightNeutralGray;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background: currentcolor;
    opacity: 0;
    width: 25%;
    aspect-ratio: 1;
    transition: 0.2s;
  }

  &:focus::after {
    opacity: 0.1;
    width: 50%;
  }

  &.active::after {
    animation: mui 0.5s ease-in-out forwards;
  }
}

%btn-navigation {
  font-size: 0.625rem;
  line-height: 1.2;
  letter-spacing: 1.15px;
  padding: 0.625rem 1.25rem;
  background-color: var.$focusBackground;
  text-align: center;
  text-transform: uppercase;
  transition: 0.2s;

  &:hover {
    background-color: color.adjust(var.$focusBackground, $lightness: -5%);
    text-decoration: none;
  }
}
