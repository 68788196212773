@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

@use 'sass:color';

$full-width: 1440px;
$header-height: 65px;
$mobile-header-height: 50px;
$secondary-header-height: 50px;

:export {
  colorOn3: var.$on3primary;
  colorGray: var.$neutralDark;
  colorBgGray: var.$focusBackground;
}

.base {
  width: 100%;

  .primary,
  .secondary {
    padding: 0 1rem;

    @media screen and (min-width: var.$desktop-width) {
      padding: 0 var.$spacing-regular;
    }
  }

  .primary {
    @include mix.flex;

    padding-left: calc(1rem - var.$spacing-sm);
    height: $mobile-header-height;

    @media screen and (min-width: var.$desktop-width) {
      height: $header-height;
      padding-left: calc(var.$spacing-regular - var.$spacing-xs);
    }

    .container {
      display: grid;
      grid-template-columns: auto 1fr auto;
    }

    [data-menu-ui='menu'] {
      display: none;

      @media screen and (min-width: var.$desktop-width) {
        display: flex;
      }
    }
  }

  .secondary {
    position: relative;
    padding: 0;

    @media screen and (min-width: var.$desktop-width) {
      padding: 0 var.$spacing-regular;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      display: block;
      top: 0;
      right: 0;
      height: 100%;
      width: var.$spacing-regular;
      background: linear-gradient(
        to right,
        rgb(0 0 0 / 0%),
        var(--header-bg-color)
      );
    }

    .container {
      @include mix.flex($gap: var.$spacing-sm);

      @extend %horizontal-scroll;

      border-top: none;
      padding: 0 1rem 0 var.$spacing-sm;
      height: $secondary-header-height;

      @media screen and (min-width: var.$desktop-width) {
        overflow-x: visible;
        border-top: 1px solid rgb(255 255 255 / 20%);
        padding: var.$spacing-sm 0;
      }
    }

    .logoWrapper {
      flex-shrink: 0;

      .logo {
        height: clamp(0.875rem, 1vw, 1rem);
      }
    }
  }

  *:not(a, button, svg) {
    background-color: inherit;
  }

  .container {
    align-items: center;
    gap: var.$spacing-xs;
    max-width: $full-width;
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: var.$desktop-width) {
      gap: clamp(var.$spacing-xs, 1vw, var.$spacing-regular);
    }
  }

  .logoWrapper {
    display: flex;
    align-items: center;
  }

  .logoLink {
    position: relative;
    z-index: 0;
    display: inline-flex;
    align-items: center;
    padding: var.$spacing-xs;
  }

  .logo {
    max-height: 2rem;
    max-width: 100%;
    width: auto;
    fill: currentcolor;

    &.on3 {
      height: 1.5rem;
    }
  }

  // Site Type themes
  &.themePrimary,
  &.themeSecondary {
    .primary {
      background: var.$neutralDark;
      color: #fff;
    }
  }

  &.themeSecondary {
    .secondary {
      background-color: var(--header-bg-color, var.$neutralDark);
      color: var(--header-text-color, #fff);
    }
  }

  &.themeSite {
    background-color: var(--header-bg-color, var.$neutralDark);
    color: var(--header-text-color, #fff);

    .secondary .container {
      @extend %horizontal-scroll;
    }
  }

  /**
  * Site theme overrides
  */

  // Pro theme
  &.isPro {
    color: var.$neutralDark;

    .logo svg {
      fill: currentcolor;
    }

    [data-join] {
      background-color: var.$neutralDark;
      color: #fff;
    }
  }

  // Nascar theme
  &.isNascar {
    color: var(--header-text-color, #fff);

    svg {
      [data-eye] {
        fill: #3b4147;
      }
    }

    [data-join] {
      background-color: #4de88d;
      color: #3b4147;
    }
  }

  // Team themes
  &.isTeam {
    .logo {
      height: clamp(1.25rem, 2vw, 1.5rem);
    }

    &,
    .primary {
      background-color: var(--header-bg-color);
    }

    .secondary {
      a[data-parent] {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.6rem;
        letter-spacing: 1.15px;
      }
    }
  }

  &.isSoftballAmerica {
    padding-left: calc(1rem - var.$spacing-sm);

    @media screen and (min-width: var.$desktop-width) {
      padding: var.$spacing-xs var.$spacing-regular;
      padding-left: calc(1rem - var.$spacing-xs);
    }

    .logo {
      height: clamp(2.5rem, 4vw, 3rem);
      max-height: 3rem;
    }
  }

  // Channel theme
  &.isChannel {
    .logo {
      @include mix.flex($gap: var.$spacing-compact);

      img {
        height: clamp(1.25rem, 1.5vw, 1.5rem);
        width: auto;
      }
    }

    .name {
      @include mix.flex($gap: var.$spacing-xs);

      font-size: clamp(0.875rem, 1.2vw, 1.125rem);
      line-height: 1;
      font-family: var.$archivo-narrow;
      text-transform: uppercase;
      color: var.$black;
      white-space: nowrap;

      @include mix.flex($gap: var.$spacing-xs);

      span:first-child {
        font-weight: 700;
      }
    }
  }

  // Elite theme
  &.isElite {
    .logo {
      height: 1.25rem;
      width: 100%;
    }
  }
}
