@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

$kentucky-blue: #1544aa;

.headerwrapper {
  position: sticky;
  top: 0;
  z-index: var.$zIndex-menu;
  box-shadow:
    0 -1px 1px rgb(0 0 0 / 10%),
    0 1px 1px rgb(0 0 0 / 10%);
  background-color: $kentucky-blue;
}

.header {
  height: 100px;
  position: sticky;
  top: 0;
  z-index: var.$zIndex-menu;
  background-color: $kentucky-blue;

  @media only screen and (min-width: 1140px) {
    height: 120px;
  }
}

.drawer {
  z-index: 9999 !important;
}

.topWrapper {
  box-shadow: inset 0 0 4px rgb(0 0 0 / 20%);
}

.topRow {
  display: flex;
  align-items: flex-end;
  max-width: 1340px;
  margin: 0 auto;
  height: 50px;
  padding: 0 15px;
  gap: 40px;
  justify-content: space-between;

  @media only screen and (min-width: 1140px) {
    justify-content: initial;
  }
}

.topLeft {
  display: flex;
  align-self: flex-end;

  @media only screen and (min-width: 1140px) {
    flex-grow: 1;
  }
}

.ksrLink {
  color: var.$white;
  border-bottom: solid 2px #406ed0;
  display: flex;
  padding: 10px 15px;
  letter-spacing: 1.15px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;

  @media only screen and (min-width: 1140px) {
    padding: 10px 30px;
  }

  .plus {
    color: var.$premiumGreen;
    margin-left: 5px;
  }

  &.active {
    border-bottom: solid 2px var.$white;
  }
}

.socialLinksContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-self: flex-end;
  padding-bottom: 15px;

  @media only screen and (max-width: 1139px) {
    position: absolute;
    top: 65px;
    right: 20px;

    &.desktopIcons {
      display: none;
    }
  }
}

.socialLink {
  display: flex;
  align-items: flex-end;

  svg {
    fill: var.$white;
    max-height: 20px;
    width: auto;
    cursor: pointer;
  }
}

.topRight {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-bottom: 10px;
  color: white;
  line-height: 1.25rem;

  button[name='user-menu'] {
    font-size: 0.75rem;
    height: 2rem;
    width: 2rem;
  }

  [data-menu-ui='user-menu'] {
    background-color: $kentucky-blue;
  }
}

.ksboard {
  display: none;
  color: var.$premiumGreen;
  gap: 5px;

  // This is the start of a new font type.
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.15px;
  text-transform: uppercase;

  @media only screen and (min-width: 1140px) {
    @include mix.flex;
  }
}

.login {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.15px;
  text-transform: uppercase;
  color: var.$white;
}

.user {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: var.$white;
  padding: 6px;
  height: 22px;
  align-self: flex-end;
  margin-bottom: 5px;

  svg {
    height: 10px;
    width: 10px;
  }

  path {
    fill: var.$nilGray;
  }
}

.menuwrapper {
  color: white;
  display: flex;
  align-items: center;
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 15px;

  @media only screen and (min-width: 1140px) {
    min-height: 72px;
  }
}

.menu {
  display: none;
  background-color: $kentucky-blue;

  @media screen and (min-width: 1140px) {
    display: block;
  }

  * {
    background-color: inherit;
  }
}

.mobileMenu {
  line-height: 1.2;
  margin-right: var.$spacing-sm;

  * {
    background-color: $kentucky-blue;
  }

  img {
    max-height: 2rem;
    width: auto;
  }

  button[name='mobile-menu'] {
    background-color: $kentucky-blue;
  }
}

.menutoggle {
  @media only screen and (min-width: 1140px) {
    display: none;
  }
}

.teambutton {
  path,
  rect {
    fill: var.$white;
  }
}

.logo {
  display: flex;
  align-items: center;
  height: 50px;
  align-self: center;

  @media only screen and (min-width: 1140px) {
    margin-right: 10px;
    height: 30px;
    border-right: solid 1px #406ed0;
  }
}

.teamlogo {
  width: auto;
  max-width: 100%;
  max-height: 30px;
  object-fit: contain;
  padding-right: 10px;

  &.ksrPlusLogo {
    max-height: 40px;

    @media only screen and (min-width: 1140px) {
      max-height: 50px;
    }
  }
}

.subButton {
  @include mix.flex($wrap: 'wrap', $justify: 'center');

  border-radius: 2px;
  padding: 4px 10px;
  color: var.$on3night;
  margin-left: auto;

  @media only screen and (min-width: 1140px) {
    padding: 4px 20px;
  }
}

.mobileMenuContainer {
  @media only screen and (min-width: 1140px) {
    display: none;
  }
}

.drawerheader {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow:
    0 -1px 1px rgb(0 0 0 / 10%),
    0 1px 1px rgb(0 0 0 / 10%);

  .button {
    width: 50px;
    color: var.$white;
  }

  .logo {
    flex-grow: 1;
    margin-right: 35px;
    display: flex;
    justify-content: center;
  }
}
