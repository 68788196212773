@use 'sass:color';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

$primary: var.$black !default;

%display {
  font-size: clamp(1.5rem, 3vw, 3rem);
  font-family: var.$geist;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 1.2;
}

%h1 {
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-family: var.$geist;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 1.15;
}

%h2 {
  font-size: 1.25rem;
  font-family: var.$geist;
  font-weight: 600;
  letter-spacing: 0.15px;
}

%h3 {
  font-size: 1.125rem;
  line-height: 1.25;
  font-family: var.$geist;
  font-weight: 900;
  letter-spacing: normal;
}

%h4 {
  font-size: 0.875rem;
  line-height: 1.25;
  font-family: var.$geist;
  font-weight: 700;
  letter-spacing: 0.15px;
}

%h5 {
  font-size: 0.875rem;
  line-height: 1.25;
  font-family: var.$archivo-narrow;
  font-weight: 700;
  letter-spacing: 0.15px;
}

%h6 {
  font-size: 0.75rem;
  line-height: 1.2;
  font-family: var.$archivo-narrow;
  font-weight: 700;
  letter-spacing: 0.15px;
}

%subheading {
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var.$lightNeutral;
}

%link {
  text-decoration: underline;
  color: $primary;
  text-underline-offset: 3px;

  &:hover {
    opacity: 0.9;
  }
}

%link-icon {
  @extend %link;

  @include mix.flex($gap: var.$spacing-xs);

  line-height: 1.2;
  letter-spacing: 0.35px;
  font-size: 0.75rem;
  width: fit-content;

  svg {
    font-size: 1.2em;
    fill: $primary;
  }
}

%link-more {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.625rem;
  letter-spacing: 1.15px;
}

%small-gray-text {
  font-size: 0.675rem;
  color: var.$gray;
  line-height: 1.2;
  letter-spacing: 0.15px;
}
