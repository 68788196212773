@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

.base {
  position: relative;
  display: grid;
  gap: 2px;

  label {
    @extend %label;
  }

  textarea {
    @extend %textarea;
  }

  .wrapper {
    @include mix.flex('row', 'stretch');

    position: relative;

    .prefix {
      display: inline-grid;
      place-items: center;
      padding: 0.5rem 0.75rem;
      border: 1px solid var.$lightNeutralGray;
      border-top-left-radius: var.$radius-sm;
      border-bottom-left-radius: var.$radius-sm;

      & + input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .helper {
    margin: 0.25rem 0 0;
    font-size: 0.75rem;
    color: var.$gray;
  }

  .error {
    @extend %error;
  }
}
